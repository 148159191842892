import React, { useRef } from "react";
import {
  Button,
  InputField,
  TextareaField,
  UploadField,
} from "../../components/commons";
import ArrowUp from "../../configuration/components/svgs/ArrowUp";
import ArrowDown from "../../configuration/components/svgs/ArrowDown";
import TrashIcon from "../../configuration/components/svgs/TrashIcon";
import { Editor } from "../../../../components/editor/Editor";

const Image = ({
  index,
  control,
  move,
  disableDown,
  disableUp,
  remove,
  setValue,
  getValues,
}) => {
  const quillRef = useRef();

  return (
    <div className="my-5">
      <div className="border-[1px] py-5 relative rounded-lg border-solid">
        <div className="px-10 py-5 flex items-center justify-center">
          <div className="flex gap-5 w-full items-center">
            <div className="flex items-center flex-1 flex-wrap w-full">
              <div className="w-full">
                <InputField
                  controller={{
                    control,
                    name: `sections.${index}.tag`,
                  }}
                  inputProps={{
                    placeholder: "Tag",
                    maxLength: 100,
                  }}
                  wrapperClassName="w-full mb-4"
                />
                <TextareaField
                  controller={{
                    control,
                    name: `sections.${index}.title`,
                  }}
                  textareaProps={{
                    placeholder: "Title",
                    maxLength: 100,
                  }}
                  wrapperClassName="w-full mb-4"
                />
                <Editor
                  ref={quillRef}
                  defaultValue={getValues(`sections.${index}.description`)}
                  onTextChange={(delta) => {
                    setValue(`sections.${index}.description`, delta, {shouldDirty: true});
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-10 pb-5 ">
          <UploadField
            imageClassName="h-[200px]"
            description="Media"
            controller={{
              control,
              name: `sections.${index}.media`,
            }}
          />
        </div>
        <div className="absolute top-2 right-[-10px]">
          <Button
            onClick={() => remove(index)}
            className="!border-none text-red-500"
            variant="icon"
            icon={<TrashIcon width={20} height={20} />}
          />
        </div>

        <div className="absolute top-1/2 right-[-50px] -translate-y-1/2">
          <div className="pb-5 ">
            <Button
              disabled={disableUp}
              onClick={() => move(index, index - 1)}
              className="!border-none"
              variant="icon"
              icon={<ArrowUp width={20} height={20} />}
            />
          </div>
          <div className="cursor-pointer">
            <Button
              disabled={disableDown}
              onClick={() => move(index, index + 1)}
              className="!border-none"
              variant="icon"
              icon={<ArrowDown width={20} height={20} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Image;
