/**
 * /* eslint-disable no-unreachable
 *
 * @format
 */

import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { routeOptions } from "./options";
import PATH from "./path";
import authApi from "../apis/api/auth";
import jwtAuth from "../apis/utils/jwtAuth";
import { useDispatch, useSelector } from "react-redux";
import { reqGetPageList } from "../reduxs/page-configuration/action";
import { reqGetModalList } from "../reduxs/home/action";
import CMSCustomers from "../pages/cms/customers";
import DynamicStaticPage from "../pages/cms/dynamic-static-page";
import { reqGetMeshes } from "../reduxs/scene/action";
import { CMSGallery } from "../pages/cms/gallery";
import { reqGetUserProfile } from "../reduxs/user/action";
import { reqGetActiveConfig } from "../reduxs/cms/action";
import { getS3BEMediaUrl } from "../helper/media";

const redirectPath = "/holding";

export const Router = () => {
  const dispatch = useDispatch();

  const authUser = useSelector((state) => state.user.data);
  const pages = useSelector((state) => state.configuration.pages);
  const staticPage = pages?.find((page) => page?.name === "static_page");

  const activeConfig = useSelector((state) => state.cms.activeConfig);

  const loadConfig = async () => {
    dispatch(reqGetActiveConfig());
  };

  useEffect(() => {
    const url = getS3BEMediaUrl(
      activeConfig?.theme?.cssVariables?.find(
        (variable) => variable.name === "logo-favicon"
      )?.value
    );
    if (url) {
      const link =
        document.querySelector("link[rel='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "icon";
      link.href = url;
      document.head.appendChild(link);
    }
  }, [activeConfig]);

  const handleUpdateVariable = (values) => {
    values.map((i) => {
      document.documentElement.style.setProperty(`--${i?.name}`, i?.value);
    });
  };

  useEffect(() => {
    if (
      activeConfig &&
      activeConfig?.theme &&
      activeConfig?.theme?.cssVariables
    ) {
      handleUpdateVariable(activeConfig?.theme?.cssVariables || []);
    }
  }, [activeConfig]);

  useEffect(() => {
    if (authUser) {
      dispatch(reqGetMeshes());
      dispatch(reqGetPageList());
      dispatch(reqGetModalList());
    }
  }, [authUser]);

  useEffect(() => {
    dispatch(reqGetUserProfile());
    loadConfig();
  }, []);

  const authMiddleware = async (
    routeRoles,
    navigate,
    redirect = redirectPath
  ) => {
    if (routeRoles.length) {
      const isToken = await jwtAuth.checkToken();
      if (isToken) {
        return getUserProfile(routeRoles, navigate, redirect);
      } else {
        return navigate(redirectPath);
      }
    }
  };

  const getUserProfile = async (
    routeRoles,
    navigate,
    redirect = redirectPath
  ) => {
    try {
      const res = await authApi.getMe();
      const userData = res?.data;
      if (
        routeRoles.length &&
        !routeRoles.includes(userData?.userGroup?.name)
      ) {
        return navigate(redirect);
      }
    } catch (error) {
      return navigate(redirect);
    }
  };

  const renderNestedRoutes = (item) => {
    if (item.subRoutes?.length) {
      return (
        <>
          <Route path="content" element={<CMSGallery />} />
          <Route path="configuration" element={<CMSConfiguration />} />
          <Route path="faqs" element={<CMSFrequentlyAQS />} />
          <Route path="residence" element={<CMSResidence />} />
          <Route path="customers" element={<CMSCustomers />} />
          <Route path="amenities" element={<CMSAmenities />} />
          <Route path="analytics" element={<CMSAnalytics />} />
          <Route path="profile" element={<CMSProfile />} />
        </>
      );
    }
    return <></>;
  };

  return (
    <BrowserRouter>
      <Routes>
        {routeOptions.map((item, index) => {
          return (
            <Route
              key={index}
              path={item.path}
              exact={item.exact}
              element={
                !item.subRoutes?.length ? (
                  <item.component
                    roles={item.roles}
                    authMiddleware={authMiddleware}
                  />
                ) : undefined
              }
            >
              {item.subRoutes?.map((subRoute, _i) => {
                return (
                  <Route
                    key={_i}
                    path={subRoute.path}
                    element={
                      <item.component
                        roles={item.roles}
                        authMiddleware={authMiddleware}
                      >
                        <subRoute.element
                          roles={subRoute.roles}
                          authMiddleware={authMiddleware}
                        />
                      </item.component>
                    }
                  />
                );
              })}
              {item.path === PATH.CMS_PAGE &&
                staticPage?.modules?.length &&
                staticPage?.modules?.map((page, index) => (
                  <Route
                    key={`cms-static-${index}`}
                    path={`/cms/${page?.props?.path}/:id`}
                    element={
                      <item.component
                        roles={item.roles}
                        authMiddleware={authMiddleware}
                      >
                        <DynamicStaticPage />
                      </item.component>
                    }
                  />
                ))}
            </Route>
          );
        })}
        <Route
          path="*"
          element={() => <Navigate to={PATH.NOTFOUND_PAGE} replace />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
