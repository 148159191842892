/** @format */

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import customerAPi from "../../../apis/api/customer";
import { toast } from "react-toastify";
import profileImage from "../../../assets/images/holding/content.jpeg";
import newProfileImage from "../../../assets/images/new-profile.png";
import Select from "react-select";
import { reqSetIsShowListInvestorModal } from "../../../reduxs/guide-session/action";
import socket from "../../../helper/socket";
import {
  ACTION_NAME,
  REACTUI_PAGES,
  WEBSOCKET_CHANNEL,
} from "../../../constants/options";
import { LongRightArrowIcon } from "../../svgs/icons";

import { reqSetPage } from "../../../reduxs/home/action";
import { getS3FEMediaUrl } from "../../../helper/media";
import classNames from "classnames";

const Profile = (props) => {
  const { startDiscover, handleCancel, agents, isPresentation } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailAddress, setEmailAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [agent, setAgent] = useState();
  const [isCreate, showCreate] = useState(false);
  const [isErrFirstName, setErrFirstName] = useState(false);
  const [isErrLastName, setErrLastName] = useState(false);
  const [isErrEmail, setErrEmail] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isErrAgent, setErrAgent] = useState(false);
  const [listUserSelect, setListUserSelect] = useState([]);
  const isShowListInvestorModal = useSelector(
    (state) => state.guideSession.isShowListInvestorModal
  );
  const isShowListConsultantModal = useSelector(
    (state) => state.guideSession.isShowListConsultantModal
  );
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    dispatch(reqSetIsShowListInvestorModal(true));

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, []);

  useEffect(() => {
    if (isShowListInvestorModal) {
      getListInvestor();
    }
    if (isShowListConsultantModal) {
      getListConsultant();
    }
  }, [isShowListInvestorModal, isShowListConsultantModal]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CREATE_PROFILE_SUCCESS) {
      onStartUserSession(content?.data?.user);
    }
  };

  const getListInvestor = async () => {
    const result = await customerAPi.getInvestorList();
    setListUserSelect(result?.data || []);
  };

  const getListConsultant = async () => {
    const result = await customerAPi.getInvestorList();
    setListUserSelect(result?.data || []);
  };

  const userSelectType = useSelector(
    (state) => state.guideSession.userSelectType
  );

  const onStartUserSession = (user) => {
    user?.id &&
      navigate({
        search: `?customer=${user.id}`,
      });
    analytics.track("Start Session", {
      customerType: userSelectType,
      customerFirstName: user?.firstname,
      customerLastName: user?.surname,
      customerEmail: user?.email,
    });

    return startDiscover(user?.id);
  };

  const handleSubmitUser = () => {
    handleValidate();
  };

  const handleValidate = async (isCreate) => {
    if (isCreate) {
      onCreateNewProfile();
    } else {
      const selectedUser = listUserSelect.find(
        (user) => user.id === customerSelected
      );
      await onStartUserSession(selectedUser);
    }
  };

  const onCreateNewProfile = async () => {
    try {
      let userGroup = "606d6f356af09f50f367ee23";

      const data = {
        userGroup: userGroup,
        email: emailAddress,
        firstname: firstName,
        surname: lastName,
        // name: name,
        // company: company,
        address: "",
        mobile: "",
        countryCode: "",
        agent,
      };

      const res = await customerAPi.createCustomer(data);
      if (res && !res.data) {
        return toast.error("Customer's email exists!");
      }

      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.CREATE_PROFILE_SUCCESS, {
          user: res.data,
        });
      }

      analytics.track("Agent Created Client", {
        agentId: res.data?.id,
        clientId: res.data?.id,
        clientFistName: res.data?.firstname,
        clientEmail: res.data?.email,
        clientSurname: res.data?.surname,
      });

      onStartUserSession(res?.data);

      toast.success(`Created successfully!`);
    } catch (err) {
      toast.error(`Customer's email already exists`);
    }
  };

  const handleCreateCustomer = async () => {
    handleValidate(true);
  };

  const handleExploreNow = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_CUBE_MENU);
    }
    dispatch(reqSetPage(REACTUI_PAGES.LANDING_PAGE));
  };

  const handleResetData = () => {
    setEmailAddress("");
    setFirstName("");
    setLastName("");
    setAgent("");
    setErrEmail(false);
    setIsEmailInvalid(false);
    setErrFirstName(false);
    setErrLastName(false);
    setErrAgent(undefined);
  };

  const createProfile = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!firstName) setErrFirstName(true);
    if (!lastName) setErrLastName(true);
    if (!emailAddress) setErrEmail(true);
    if (!agent) setErrAgent(true);

    if (!emailPattern.test(emailAddress)) {
      setIsEmailInvalid(true);
      return;
    }

    if (firstName && lastName && emailAddress) handleCreateCustomer();
    return;
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "#ffffff",
      border: isErrAgent ? "1px solid #c02b0a" : "none",
      boxShadow: "none",
      "&:hover": {
        border: isErrAgent ? "1px solid #c02b0a" : "none",
      },
      padding: "8px 4px 8px 12px",
      borderRadius: "0",
      fontSize: "14px",
      minHeight: "64px",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#000000",
      fontWeight: "500",
      textAlign: "left",
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      backgroundColor: isSelected
        ? "#f4f4f4"
        : isFocused
        ? "#f4f4f4"
        : "#ffffff",
      "&:active": {
        backgroundColor: "#f4f4f4",
      },
      fontSize: undefined,
      color: undefined,
      padding: "16px 20px",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "0",
      marginTop: "0",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    }),
    menuList: (base) => ({
      ...base,
      padding: "0",
    }),
    valueContainer: (base) => ({
      ...base,
    }),
    singleValue: () => ({ gridArea: "1 / 1" }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "0 8px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };

  const [customerSelected, setCustomerSelected] = useState(null);

  const ErrMessage = () => (
    <p className="text-left text-[#c02b0a] text-[12px] font-suisse mt-[5px]">
      This field is required
    </p>
  );
  const InvalidEmailMessage = () => (
    <p className="text-left text-[#c02b0a] text-[12px] font-suisse mt-[5px]">
      Email not valid
    </p>
  );

  const inputStyle = `text-p text-invert outline-none placeholder:text-p placeholder:text-invert w-full h-[64px] border-none bg-[#f4f4f4] pl-[20px]`;
  const renderCreateProfile = () => {
    return (
      <div
        id="select-profile-wrapper"
        className="absolute inset-0 w-full h-full bg-brand text-primary  overflow-y-scroll p-[89px_109px]"
      >
        <div className="flex gap-[40px]">
          <div className="flex flex-col self-end h-full items-center justify-center gap-[30px] w-[534px]">
            <div className="h-[88px] text-left w-full">
              <h2 className="text-hero !font-normal">
                NEW
                <br />
                PROFILE
              </h2>
            </div>
            <div className="w-full h-[1px] bg-white/10" />
            <div className="flex flex-col items-start w-full pb-[40px] px-0 gap-[30px]">
              <p className="text-p text-primary text-left">
                First time at Eywa? Enter your details below to create a new
                customer profile.
              </p>
              <div className="w-full grid gap-y-[25px] gap-x-[15px] grid-cols-2 ">
                <div className="relative flex flex-col">
                  <input
                    className={inputStyle}
                    type="text"
                    placeholder="FIRST NAME"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setErrFirstName(false);
                    }}
                    style={{
                      border: isErrLastName ? "1px solid #c02b0a" : "none",
                    }}
                  />
                  {isErrFirstName && <ErrMessage />}
                  {firstName && (
                    <span className="place-holder absolute left-[20px] top-[7px] text-[8px] text-[#24242299] font-normal">
                      FIRST NAME
                    </span>
                  )}
                </div>
                <div className="relative flex flex-col">
                  <input
                    className={inputStyle}
                    type="text"
                    placeholder="LAST NAME"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setErrLastName(false);
                    }}
                    style={{
                      border: isErrLastName ? "1px solid #c02b0a" : "none",
                    }}
                  />
                  {isErrLastName && <ErrMessage />}
                  {lastName && (
                    <span className="place-holder absolute left-[20px] top-[7px] text-[8px] text-[#24242299] font-normal">
                      LAST NAME
                    </span>
                  )}
                </div>
                <div className="relative flex flex-col">
                  <input
                    className={inputStyle}
                    type="text"
                    placeholder="EMAIL"
                    name="email"
                    value={emailAddress}
                    onChange={(e) => {
                      setEmailAddress(e.target.value);
                      setErrEmail(false);
                      setIsEmailInvalid(false);
                    }}
                    style={{
                      border:
                        isErrEmail || isEmailInvalid
                          ? "1px solid #c02b0a"
                          : "none",
                    }}
                  />
                  {isErrEmail ? (
                    <ErrMessage />
                  ) : (
                    <>{isEmailInvalid && <InvalidEmailMessage />}</>
                  )}
                  {emailAddress && (
                    <span className="place-holder absolute left-[20px] top-[7px] text-[8px] text-[#24242299] font-normal">
                      EMAIL
                    </span>
                  )}
                </div>
                <div className="relative flex flex-col">
                  <Select
                    className={`w-full h-full text-left focus:outline-none`}
                    classNamePrefix={isErrAgent ? "select-error" : "select"}
                    defaultValue={{ value: "", label: "SALES AGENT" }}
                    isDisabled={false}
                    isSearchable={false}
                    options={agents?.map(({ id, firstname }) => ({
                      value: id,
                      label: firstname,
                    }))}
                    name="type"
                    onChange={(item) => {
                      setAgent(item.value);
                      setErrAgent(false);
                    }}
                    style={{
                      border: isErrAgent ? "1px solid #c02b0a" : "none",
                    }}
                    styles={customStyles}
                    classNames={{
                      singleValue: () => "text-p text-invert",
                      option: () => "text-p text-invert",
                    }}
                  />
                  {isErrAgent && <ErrMessage />}
                </div>
              </div>
              <button
                onClick={createProfile}
                className="w-full h-[60px] border-none bg-buttonPrimary text-p text-primary "
              >
                SUBMIT
              </button>
              <div className="button-group flex">
                <button
                  onClick={() => {
                    showCreate(false);
                    handleResetData();
                  }}
                  className="text-textLink"
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
          <div>
            <p className="border-[#FFF] border-opacity-10 border-l h-full"></p>
          </div>
          <img
            className="object-cover flex-1 h-[846px]"
            src={newProfileImage}
            alt="create-profile-image"
            width="534px"
            height="846px"
          />
        </div>
      </div>
    );
  };

  const renderInputProfile = () => {
    return (
      <div
        id="select-profile-wrapper"
        className="absolute inset-0 w-full h-full bg-brand text-primary  overflow-y-scroll p-[89px_109px]"
      >
        <div className="flex gap-[40px]">
          <div className="flex flex-col self-end h-full items-center justify-center gap-[30px] w-[534px]">
            <div className="h-[88px] text-left w-full">
              <h2 className="text-hero !font-normal">
                SELECT
                <br />
                PROFILE
              </h2>
            </div>
            <div className="w-full h-[1px] bg-white/10" />
            <div className="flex flex-col items-start w-full pb-[40px] px-0 gap-[30px]">
              <p className="text-p text-primary">
                Select the customer profile for today's session
              </p>
              <input
                type="text"
                className={inputStyle}
                placeholder="SEARCH ..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <ul className="p-0 w-full h-[200px] overflow-y-hidden">
                {listUserSelect
                  ?.filter((user) => {
                    if (searchInput == "") {
                      return user;
                    } else if (
                      user?.name
                        .toLowerCase()
                        .includes(searchInput.toLocaleLowerCase())
                    ) {
                      return user;
                    }
                  })
                  .map(({ id, name }) => (
                    <li
                      className={classNames(
                        ` list-none text-textLink uppercase flex items-center h-[40px] pl-[20px] `,
                        customerSelected === id
                          ? "border-2 border-white"
                          : "border-b border-b-white border-opacity-10 first:border-t-white border-t first:border-opacity-10"
                      )}
                      onClick={() => setCustomerSelected(id)}
                      key={id}
                    >
                      {name}
                    </li>
                  ))}
              </ul>
              <button
                className="w-full h-[60px] bg-buttonPrimary text-p text-primary text-center"
                onClick={handleSubmitUser}
              >
                SELECT PROFILE
              </button>
              <div className="button-group flex justify-between w-full">
                <div>
                  <button
                    className="bg-transparent text-textLink p-0 mr-[24px]"
                    onClick={() => handleCancel()}
                  >
                    CANCEL
                  </button>
                  <button
                    className="bg-transparent text-textLink p-0"
                    onClick={() => showCreate(true)}
                  >
                    CREATE A NEW PROFILE
                  </button>
                </div>
                <div
                  className="explore-btn cursor-pointer flex items-center gap-[10px]"
                  onClick={handleExploreNow}
                >
                  <button
                    className="text-textLink text-primary"
                    onClick={() => handleCancel()}
                  >
                    EXPLORE NOW
                  </button>
                  <LongRightArrowIcon
                    className="text-primary"
                    fill="currentColor"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="border-[#FFF] border-opacity-10 border-l h-full"></p>
          </div>
          <img
            className="object-cover flex-1 h-[846px]"
            src={profileImage}
            alt="create-profile-image"
            width="534px"
            height="846px"
          />
        </div>
      </div>
    );
  };

  return (
    <div
      id="customer-profile"
      style={{ backgroundImage: `url(${getS3FEMediaUrl("/bg-holding.png")})` }}
    >
      {isCreate ? renderCreateProfile() : renderInputProfile()}
    </div>
  );
};

export default Profile;
