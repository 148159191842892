/** @format */

import React, { useRef, useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useJsApiLoader } from "@react-google-maps/api";
import _ from "lodash";
import { mapStyles } from "./map-config";
import socket from "../../helper/socket";
import MainLocations from "./location-list/MainLocations";
import LocationDetail from "./location-detail/LocationDetail";
import Map from "./map-2d/Map";
import { reqGetPoiList, reqSetActivePoi } from "../../reduxs/locations/action";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import { MODULES_ENUM, PAGES_ENUM } from "../../constants/modules";

const MapsApiKey = "AIzaSyC2fmTkp-ummiBHRE2ee4MVD_rr-pZ9fcI";
const Categories = [
  "Airports",
  "Cafes",
  "Fine Dining & Cocktails",
  "Hotels",
  "Playgrounds & Schools",
  "Shopping & Grocery",
  "Spa & Wellness",
  "Sports & Recreation",
  "Veterinary Clinics & Dog Parks",
];

const TV_WIDTH = 2000;

const activeMarkers = [];

let map;

const Location = (props) => {
  const { visible = false, moduleConfig = null, isPresentation } = props;

  const pois = useSelector((state) => state.poi.data).sort(
    (a, b) => a.index - b.index
  );
  const activePoi = useSelector((state) => state.poi.activePoi);
  const activeType = useSelector((state) => state.poi.activeType);
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const pageRef = useRef(null);
  const [activeCategory, setActiveCategory] = useState("");
  const activeConfig = useSelector((state) => state.cms.activeConfig);
  const currentMapStyles = {
    default: {
      ...mapStyles.default,
      ...(activeConfig?.theme?.mapStyles && {
        rules: activeConfig?.theme?.mapStyles,
      }),
    },
  };
  useEffect(() => {
    dispatch(reqGetPoiList());
  }, []);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: MapsApiKey,
  });

  const onMapLoaded = (mapRef) => {
    map = mapRef;
    mapRef.setOptions({ styles: currentMapStyles.default.rules });
  };

  useEffect(() => {
    // Set the color of the map
    const key = activeType === "" ? "default" : activeType;
    const styles =
      currentMapStyles[
        Object.keys(currentMapStyles).includes(key) ? key : "default"
      ].rules;
    if (!!mapRef?.current?.state?.map) {
      mapRef.current.state.map.setOptions({ styles });
    }
  }, [isLoaded, currentMapStyles]);

  useEffect(() => {
    mapRef?.setOptions?.({ styles: currentMapStyles.default.rules });
  }, [mapRef, currentMapStyles]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CLICK_POI) {
      handleClickPoint(content.data.poi);
    }

    if (content.action === ACTION_NAME.CLICK_POI_ITEM) {
      handleShowItem(content.data.item);
    }

    if (content.action === ACTION_NAME.CLICK_POI_GROUP) {
      handleShowCategory(content.data.item);
    }

    if (content.action === ACTION_NAME.GOOGLE_MAP_ZOOM_CHANGED) {
      if (mapRef.current) {
        const isTV = window.screen.width > TV_WIDTH;
        mapRef.current.state.map.setZoom(
          isTV ? content.data.zoom + 1 : content.data.zoom
        );
      }
    }

    if (content.action === ACTION_NAME.GOOGLE_MAP_CENTER_CHANGED) {
      if (mapRef.current) {
        mapRef.current.state.map.setCenter({
          lat: content.data.lat,
          lng: content.data.lng,
        });
      }
    }

    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      pageRef.current &&
        (pageRef.current.scrollTop =
          content.data.scrollTop * pageRef.current.scrollHeight);
    }
  };

  useEffect(() => {
    dispatch(reqGetPoiList());
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const showInfoWindow = (data) => {
    dispatch(reqSetActivePoi(data.id));
  };

  const handleClickSubPoint = (poi) => {
    showInfoWindow(poi);
  };

  const handleClickPoint = (poi) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_POI, {
        poi,
      });
    }
    setActiveCategory("");
    showInfoWindow(poi);
  };

  const handleShowItem = (poi) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_POI_ITEM, {
        item: poi,
      });
    }
    if (poi && mapRef && mapRef.current) {
      poi.marker?.coordinates &&
        mapRef.current.state.map.setCenter({
          lat: poi.marker.coordinates.lat,
          lng: poi.marker.coordinates.lng,
        });
      poi.marker?.coordinates && mapRef.current.state.map.setZoom(18);
    }
    handleClickSubPoint(poi);
    poi && handleShowCategory("");
  };

  const handleShowCategory = (category) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_POI_GROUP, {
        item: category,
      });
    }

    const bounds = new google.maps.LatLngBounds();
    pois.forEach((poi) => {
      if (poi.category === category) {
        bounds.extend({
          lat: poi.marker.coordinates.lat,
          lng: poi.marker.coordinates.lng,
        });
      }
    });
    if (category && mapRef && mapRef.current && map) {
      map.fitBounds(bounds, 200);
    }
    setActiveCategory(category);
    category && handleShowItem("");
  };

  const setActivePoi = (data) => {
    dispatch(reqSetActivePoi(""));
    dispatch(reqSetActivePoi(data.id));
  };

  const clearActivePoi = () => {
    dispatch(reqSetActivePoi(""));
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop:
          event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };

  if (!visible) return null;

  return (
    <>
      <MainLocations
        categories={Categories}
        ref={pageRef}
        onScroll={onScroll}
        onClickTitle={handleShowCategory}
        onClickCategory={handleShowCategory}
        onClickItem={handleShowItem}
        pois={pois}
        activeCategory={activeCategory}
        activePoi={activePoi}
        featureConfig={
          moduleConfig?.features?.find(
            (f) => f.name === MainLocations.displayName
          ) || null
        }
        {...(moduleConfig?.features?.find(
          (f) => f.name === MainLocations.displayName
        )?.props || {})}
      />
      <div className="absolute z-[90] w-full h-full top-0 left-0 overflow-hidden bg-black">
        {isLoaded && (
          <Map
            ref={mapRef}
            onMapLoaded={onMapLoaded}
            onZoomChanged={function () {
              if (!isPresentation) {
                socket.emitUIActionEvent(ACTION_NAME.GOOGLE_MAP_ZOOM_CHANGED, {
                  zoom: this.zoom,
                });
              }
            }}
            onCenterChanged={function () {
              if (!isPresentation) {
                socket.emitUIActionEvent(
                  ACTION_NAME.GOOGLE_MAP_CENTER_CHANGED,
                  {
                    lat: this.center.lat(),
                    lng: this.center.lng(),
                  }
                );
              }
            }}
            pois={pois}
            activeCategory={activeCategory}
            activePoi={activePoi}
            activeMarkers={activeMarkers}
            handleClickPoint={handleClickPoint}
            activeType={activeType}
            clearActivePoi={clearActivePoi}
            setActivePoi={setActivePoi}
            featureConfig={
              moduleConfig?.features?.find((f) => f.name === Map.displayName) ||
              null
            }
            {...(moduleConfig?.features?.find((f) => f.name === Map.displayName)
              ?.props || {})}
          />
        )}
        <LocationDetail
          pois={pois}
          activePoi={activePoi}
          featureConfig={
            moduleConfig?.features?.find(
              (f) => f.name === LocationDetail.displayName
            ) || null
          }
          {...(moduleConfig?.features?.find(
            (f) => f.name === LocationDetail.displayName
          )?.props || {})}
        />
      </div>
    </>
  );
};
// "module" component should have "displayName" and "pageGroup"
Location.displayName = MODULES_ENUM.LOCATION;
Location.pageGroup = PAGES_ENUM.LOCATION;

export default Location;
