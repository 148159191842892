import React from "react";
import { COMPONENTS_ENUM } from "../../../constants/modules";

const UnitName = (props) => {
  const { visible = false, componentConfig = null, unit } = props;

  if (!visible) return null;

  return (
    <div className="p-0 mb-[30px] tracking-[0.07rem]">
      <h2 className="text-primary text-panelHeader">{unit?.name}</h2>
    </div>
  );
};
UnitName.displayName = COMPONENTS_ENUM.UNIT_NAME;

export default UnitName;
