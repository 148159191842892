import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import socket from "../../../helper/socket";
import React, { useEffect, useMemo } from "react";
import * as unitExploreAct from "../../../reduxs/unit-explore/action";
import { WEBSOCKET_CHANNEL, ACTION_NAME } from "../../../constants/options";

const UnitFilterFooter = ({ isPresentation = false }) => {
  const dispatch = useDispatch();

  const isShowUnitList = useSelector(
    (state) => state.unitExplore.isShowUnitList
  );
  const isShowFilter = useSelector((state) => state.unitExplore.isShowFilter);
  const filterUnitBedroom = useSelector(
    (state) => state.unitExplore.filterUnitBedroom
  );
  const filterUnitBathroom = useSelector(
    (state) => state.unitExplore.filterUnitBathroom
  );
  const filterUnitPrice = useSelector(
    (state) => state.unitExplore.filterUnitPrice
  );
  const filterUnitLotSize = useSelector(
    (state) => state.unitExplore.filterUnitLotSize
  );
  const filterUnitExteriorSize = useSelector(
    (state) => state.unitExplore.filterUnitExteriorSize
  );
  const filterUnitInteriorSize = useSelector(
    (state) => state.unitExplore.filterUnitInteriorSize
  );
  const filterUnitAvailability = useSelector(
    (state) => state.unitExplore.filterUnitAvailability
  );
  const filterUnitPool = useSelector(
    (state) => state.unitExplore.filterUnitPool
  );
  const filterUnitAspect = useSelector(
    (state) => state.unitExplore.filterUnitAspect
  );

  useEffect(() => {
    function handleUnitExplorerPageEvent(e) {
      onResetAllFilter({ auto_available: e?.detail === "auto_available" });
    }
    document.addEventListener(
      "UNIT_EXPLORER_PAGE",
      handleUnitExplorerPageEvent
    );
    return () => {
      document.removeEventListener(
        "UNIT_EXPLORER_PAGE",
        handleUnitExplorerPageEvent
      );
    };
  }, []);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const listenerSharedUIAction = ({ content }) => {
    switch (content?.action) {
      case ACTION_NAME.CLEAR_ALL_FILTERS:
        onResetAllFilter(content.data?.options || {});
        break;
      case ACTION_NAME.HIDE_FILTER:
        onHideFilter();
        break;
      default:
        console.log(`unsupported action "${content?.action}"`);
        return;
    }
  };

  const onResetAllFilter = (options = {}) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLEAR_ALL_FILTERS, {
        options,
      });
    }
    if (options?.auto_available) {
      // dispatch(unitExploreAct.reqSetIsShowUnitList(true));
      dispatch(unitExploreAct.reqFilterUnitAvailability([]));
    } else {
      dispatch(unitExploreAct.reqFilterUnitAvailability([]));
      dispatch(unitExploreAct.reqSetIsShowUnitList(false));
    }
    dispatch(unitExploreAct.reqFilterUnitBedroom([]));
    dispatch(unitExploreAct.reqFilterUnitBathroom([]));
    dispatch(unitExploreAct.reqFilterUnitAspect(""));
    dispatch(unitExploreAct.reqFilterUnitPool());
    dispatch(unitExploreAct.reqFilterUnitPrice(""));
    dispatch(unitExploreAct.reqFilterUnitLotSize(""));
    dispatch(unitExploreAct.reqSetSelectedUnit(""));
  };

  const onHideFilter = () => {
    if (!isShowUnitList) {
      return;
    }
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.HIDE_FILTER);
    }
    dispatch(unitExploreAct.reqSetIsShowFilter(false));
  };

  const clearBtnClassName = useMemo(() => {
    let disableClassName = "opacity-25";
    if (
      (isShowUnitList && isShowFilter) ||
      filterUnitAvailability.length ||
      filterUnitBedroom.length ||
      filterUnitBathroom.length ||
      filterUnitPrice !== "" ||
      filterUnitLotSize !== "" ||
      filterUnitExteriorSize !== "" ||
      filterUnitInteriorSize !== "" ||
      filterUnitPool !== undefined
    ) {
      disableClassName = "";
    }

    return disableClassName;
  }, [
    filterUnitAspect,
    filterUnitPool,
    isShowUnitList,
    isShowFilter,
    filterUnitBedroom,
    filterUnitBathroom,
    filterUnitPrice,
    filterUnitLotSize,
    filterUnitExteriorSize,
    filterUnitInteriorSize,
    filterUnitAvailability,
  ]);

  const hideBtnClassName = useMemo(() => {
    let disableClassName = isShowUnitList ? "" : "opacity-25";

    return disableClassName;
  }, [isShowUnitList]);

  if (isPresentation) return null; // BN-1775

  return (
    <div className="font-sans text-sm font-normal leading-none tracking-[1.4px] uppercase flex flex-col gap-[15px] items-start w-[83%] z-10 cursor-pointer text-primary">
      <span
        onClick={() => onResetAllFilter()}
        className={`text-textLink text-primary ${clearBtnClassName}`}
      >
        CLEAR FILTERS
      </span>
      <span
        onClick={onHideFilter}
        className={`text-textLink text-primary ${clearBtnClassName}`}
      >
        HIDE FILTERS
      </span>
    </div>
  );
};

export default UnitFilterFooter;
