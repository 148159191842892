import React from 'react';
import logo from "../../assets/images/logo.svg";
import { getS3BEMediaUrl } from '../../helper/media';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';

const HoldingPageWrapper = ({
  children,
}) => {
  const activeConfig = useSelector((state) => state.cms.activeConfig);
  const logo = activeConfig?.theme?.cssVariables?.find(
    (variable) => variable.name === "logo"
  )?.value;
  return (
    <div className="flex justify-center items-center h-full bg-brand">
      <div className={`text-sm w-[666px] px-10 py-9 shadow-none flex flex-col items-center text-black`}>
        <div className="flex justify-center w-11/12 pb-[5px]">
          {logo && (
            <LazyLoadImage
              src={getS3BEMediaUrl(logo)}
              alt="bay-harbor-tower"
              className="max-w-[291px] mt-[-100px]"
            />
        )}
        </div>
        <div className="mt-12">
          {children}
        </div>
      </div>
    </div>
  );
};

export default HoldingPageWrapper;
