/** @format */

import React from "react";
import LocationGroup from "./components/LocationGroup";
import { FEATURES_ENUM } from "../../../constants/modules";

const MainLocations = React.forwardRef((props, ref) => {
  const {
    onScroll,
    onClickTitle,
    onClickCategory,
    onClickItem,
    categories,
    pois,
    activeCategory,
    activePoi,
    visible = false,
    featureConfig = null,
    label = "Points Of Interest",
  } = props;

  if (!visible) return null;

  return (
    <div className="absolute left-0 top-[122px] z-[100] flex w-[285px] h-[calc(100dvh-122px-122px)] p-[30px_35px] flex-col items-start gap-[30px] flex-shrink-0 overflow-hidden border border-[rgba(36,36,34,0.15)] bg-[--location-sidebar-background-color]">
      <div
        className="self-stretch px-0 pt-0 pb-[30px] border-b-[1px] border-white/10 text-[--location-sidebar-header-color] text-panelHeader cursor-pointer [text-stroke-width:0.25px] [text-stroke-color:#242422]"
        onClick={() => onClickTitle("")}
      >
        {label === "POINTS OF INTEREST" && "Points Of Interest"}
      </div>
      <div
        className="flex flex-col items-start gap-[30px] flex-shrink-0 self-stretch h-[calc(100%-100px)] overflow-auto overflow-x-hidden w-[220px]"
        ref={ref}
        onScroll={onScroll}
      >
        {categories.map((category, key) => (
          <LocationGroup
            key={key}
            category={category}
            pois={pois}
            onClickCategory={onClickCategory}
            onClickItem={onClickItem}
            activeCategory={activeCategory}
            activePoi={activePoi}
            {...(featureConfig?.components?.find(
              (c) => c.name === LocationGroup.displayName
            )?.props || {})}
          />
        ))}
      </div>
    </div>
  );
});
MainLocations.displayName = FEATURES_ENUM.LIST_POIS;

export default MainLocations;
