/** @format */

import {
  reqSetPage,
  reqSetIsTransparent,
  reqSetActiveGalleryId,
} from "../../reduxs/home/action";
import {
  reqSetIsShowExploreModal,
  reqSetIsShowAmenityVirtualTour,
} from "../../reduxs/explore-modal/action";
import socket from "../../helper/socket";
import React, { useEffect } from "react";
import {
  MODULES_ENUM,
  PAGES_ENUM,
  TYPE_STATIC_PAGE_ENUM,
} from "../../constants/modules";
import { useDispatch, useSelector } from "react-redux";
import closeIcon from "../../assets/images/close-v3.svg";
import icBayHarbor from "../../assets/images/logo-bay-harbor.svg";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import {
  ACTION_NAME,
  REACTUI_PAGES,
  WEBSOCKET_CHANNEL,
} from "../../constants/options";
import { reqGetPageList } from "../../reduxs/page-configuration/action";
import { getS3BEMediaUrl } from "../../helper/media";
import icBayHarborTower from "../../assets/images/icBayHarborTower.png";
import { cn } from "../../helper/utils";

const TopNav = (props) => {
  const {
    isShow,
    handleClickCube,
    handleLocation,
    handleNeighborhood,
    handlePpg,
    handleClickResidences,
    handleAmenities,
    handleTransportOptions,
    isPresentation,
    handleClickGalleryB,
    handleClickGalleryA,
  } = props;
  const dispatch = useDispatch();
  const pages = useSelector((state) => state.configuration.pages);
  const reactuiPageActive = useSelector((state) => state.home.reactUiPage);
  const isShowViewLine = useSelector(
    (state) => state.unitExplore.isShowViewLine
  );
  const isShowReplayVideo = useSelector(
    (state) => state.precinctExplore.isShowReplayVideo
  );
  const isShowAmenityVirtualTour = useSelector(
    (state) => state.exploreModal.isShowAmenityVirtualTour
  );
  const reactUiPage = useSelector((state) => state.home.reactUiPage);
  const unit = useSelector((state) => state.unitExplore.selectedUnit);
  const modal = useSelector((state) => state.exploreModal.modal);
  const staticPagePath = useSelector(
    (state) => state.gallery.contentStaticType1
  )?.path;
  const activeConfig = useSelector((state) => state.cms.activeConfig);
  const logo = activeConfig?.theme?.cssVariables?.find(
    (variable) => variable.name === "logo"
  )?.value;

  const onClickCube = () => {
    handleClickCube();
  };

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CLOSE_UNIT_VIEWLINE) {
      hideViewLines();
    }
    if (content.action === ACTION_NAME.CLOSE_AMENITY_VIRTUAL_TOUR) {
      hideAmenityVirtualTour();
    }
  };

  useEffect(() => {
    isShow && onClickCube();
  }, [isShow]);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const isActive = (menu) => {
    return reactuiPageActive === menu
      ? "before:bg-[var(--navigation-text-color)]  before:content-[''] before:h-1 before:absolute before:w-1 before:rounded-[100%] before:bottom-2.5"
      : "";
  };

  const hideViewLines = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_VIEWLINE);
    }
    dispatch(unitExploreAct.reqIsShowViewLine(false));
    dispatch(reqSetIsTransparent(false));
    dispatch(reqSetActiveGalleryId(""));

    if (reactUiPage === REACTUI_PAGES.VIEWS_LEVEL)
      dispatch(reqSetPage(REACTUI_PAGES.VIEWS_PAGE));
    else dispatch(reqSetPage(REACTUI_PAGES.UNIT_EXPLORER_PAGE));
  };

  const hideAmenityVirtualTour = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_AMENITY_VIRTUAL_TOUR);
    }
    dispatch(reqSetIsShowExploreModal(true));
    dispatch(reqSetIsShowAmenityVirtualTour(false));
  };

  const PageOnboardTopNav = () => (
    <div
      className={`items-center bg-navigation cursor-pointer flex h-[70px] justify-center absolute w-full z-[111] px-10 py-[15px] border-b-navigation border-b border-opacity-10 top-0 ${
        isPresentation && "invisible"
      }`}
    >
      {/* <img src={icBayHarbor} alt="icon-cube" onClick={onClickCube} /> */}
      <div
        className="font-domaine text-navigation uppercase text-2xl leading-5 cursor-pointer w-[50px] h-[30px]"
        onClick={onClickCube}
      >
        {logo && (
          <img
            src={getS3BEMediaUrl(logo)}
            alt="bay-harbor-tower"
            className="w-full h-full"
          />
        )}
      </div>
    </div>
  );

  if (
    reactUiPage === REACTUI_PAGES.ONBOARD_PAGE &&
    !isShow &&
    !isShowReplayVideo
  ) {
    return <PageOnboardTopNav />;
  }

  const NAV_ITEMS = [
    {
      pageKey: PAGES_ENUM.INTERACTIVE_3D,
      moduleKey: MODULES_ENUM.UNIT_EXPLORE,
      label: "RESIDENCES",
      className: isActive(REACTUI_PAGES.UNIT_EXPLORER_PAGE),
      onClick: handleClickResidences,
    },
    {
      pageKey: PAGES_ENUM.INTERACTIVE_3D,
      moduleKey: MODULES_ENUM.AMENITY_EXPLORE,
      label: "AMENITIES",
      className: isActive(REACTUI_PAGES.AMENITIES_PAGE),
      onClick: handleAmenities,
    },
    {
      pageKey: PAGES_ENUM.INTERACTIVE_3D,
      moduleKey: MODULES_ENUM.TRANSPORT_OPTIONS,
      label: "TRANSPORT",
      className: isActive(REACTUI_PAGES.EXPLORE_TRANSPORTS_PAGE),
      onClick: handleTransportOptions,
    },
    {
      pageKey: PAGES_ENUM.LOCATION,
      label: "LOCATION",
      className: isActive(REACTUI_PAGES.LOCATION_PAGE),
      onClick: handleLocation,
    },
    {
      pageKey: PAGES_ENUM.GALLERY_TYPE_B,
      label: "GALLERY B",
      className: isActive(REACTUI_PAGES.GALLERY_PAGE_TYPE_B),
      onClick: handleClickGalleryB,
    },
    {
      pageKey: PAGES_ENUM.GALLERY_TYPE_A,
      label: "GALLERY",
      className: isActive(REACTUI_PAGES.GALLERY_PAGE_TYPE_A),
      onClick: handleClickGalleryA,
    },
  ];

  const clickStaticPage = (item) => {
    if (item.type === TYPE_STATIC_PAGE_ENUM.template_1) {
      handleNeighborhood(item);
    } else {
      handlePpg(item);
    }
  };

  const renderNavItems = () => {
    const listNav = [];
    pages?.forEach((page) => {
      if (page.name === PAGES_ENUM.STATIC_PAGE) {
        page.modules?.map((i) => {
          const newNav = {
            pageKey: i.name,
            label: i.name,
            className: isActive(i.name),
            isStatic: true,
            visible: i?.props?.visible,
            type: i?.props?.type,
            sections: i?.props.sections,
            path: i?.props.path,
            onClick: () =>
              clickStaticPage({
                type: i?.props?.type,
                sections: i?.props.sections,
                path: i?.props.path,
              }),
          };
          if (!page?.props?.visible) {
            newNav.visible = false;
          }
          listNav.push(newNav);
        });
      } else {
        const pageNavObjs = NAV_ITEMS.filter(
          (nav) => nav.pageKey === page.name
        );
        pageNavObjs?.forEach((navObj, _i) => {
          navObj.visible = page?.props?.visible;
          if (navObj?.moduleKey && page?.props?.visible) {
            const module = page.modules?.find(
              (m) => m.name === navObj.moduleKey
            );
            navObj.visible = !!module?.props?.visible;
          }
          listNav.push(navObj);
        });
      }
    });
    return listNav.map((item, _i) => {
      if (item?.visible) {
        return (
          <li
            key={_i}
            className={cn(
              `items-center flex flex-col justify-center leading-[normal] list-none`,
              item.className,
              {
                "before:bg-[var(--navigation-text-color)]  before:content-[''] before:h-1 before:absolute before:w-1 before:rounded-[100%] before:bottom-2.5":
                  reactUiPage === REACTUI_PAGES.NEIGHBORHOOD_PAGE &&
                  item?.path === staticPagePath,
              }
            )}
            onClick={() => {
              item.onClick();
              dispatch(reqGetPageList());
            }}
          >
            <span className="text-center text-navigation  not-italic text-textLink uppercase cursor-pointer block no-underline p-0">
              {item.label}
            </span>
          </li>
        );
      }
      return null;
    });
  };

  return (
    <>
      <div
        className={`w-full h-[49px] absolute z-[1111]  pointer-events-auto flex justify-between items-center px-10 py-[15px] border-b-navigation border-solid top-0 ${
          isPresentation ? "invisible" : "bg-navigation border-b"
        }`}
      >
        {(isShow || isShowReplayVideo) && !isPresentation && (
          <div className="flex justify-between w-full">
            {/* <img
              className="cursor-pointer object-contain"
              src={icBayHarbor}
              alt="icon-cube-small"
              onClick={onClickCube}
            /> */}
            <div
              className="font-domaine text-navigation uppercase text-2xl leading-5 cursor-pointer w-[50px] h-[30px]"
              onClick={onClickCube}
            >
              {logo && (
                <img
                  src={getS3BEMediaUrl(logo)}
                  alt="bay-harbor-tower"
                  className="w-full h-full"
                />
              )}
            </div>
            <ul className="flex justify-center items-center gap-[35px] w-fit m-0 p-0;">
              {renderNavItems()}
            </ul>
          </div>
        )}
      </div>
      {isShowViewLine && (
        <div className="wrap-top-nav second-nav">
          <div className="top-nav">
            <div className="unit-name text-primary">RESIDENCE {unit?.name}</div>
            <img
              onClick={hideViewLines}
              src={closeIcon}
              className="close-icon-nav"
            />
          </div>
        </div>
      )}

      {isShowAmenityVirtualTour && (
        <div className="wrap-top-nav second-nav">
          <div className="top-nav">
            <div className="amenity-top-nav-title">{modal?.name}</div>
            <img
              onClick={hideAmenityVirtualTour}
              src={closeIcon}
              className="close-icon-nav"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TopNav;
