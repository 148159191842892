/** @format */

import masterData from "./master-data";

export const ROOM_TYPE_OPTIONS = [
  {
    id: 1,
    value: "unfurnished",
    title: "Unfurnished",
  },
  {
    id: 2,
    value: "furnished",
    title: "Furnished",
  },
];

export const AVAILABILITY_OPTIONS = [
  {
    id: 1,
    value: "available",
    title: "Available",
  },
  {
    id: 2,
    value: "reserved",
    title: "Reserved",
  },
  {
    id: 3,
    value: "sold",
    title: "Sold",
  },
  {
    id: 4,
    value: "releasing_soon",
    title: "Releasing Soon",
  },
];

export const MEDIA_TYPE_OPTION = [
  {
    value: masterData.MEDIA_TYPE_ENUM["360_PANO_TOUR"],
    title: "360 Pano tour",
  },
  {
    value: masterData.MEDIA_TYPE_ENUM.FLOORPLAN,
    title: "Floorplan",
  },
  {
    value: masterData.MEDIA_TYPE_ENUM.IMAGE,
    title: "Image",
  },
  {
    value: masterData.MEDIA_TYPE_ENUM.INTERIOR_ID,
    title: "Interior id",
  },
  {
    value: masterData.MEDIA_TYPE_ENUM.VIDEO,
    title: "Video",
  },
];

export const ASPECT_OPTION = [
  {
    id: 1,
    name: "Sea view",
    value: "sea_view",
    description: "Face the sea",
  },
  {
    id: 2,
    name: "City view",
    value: "city_view",
    description: "Face the city",
  },
];

export const PROPERTY_TYPE_OPTION = [
  {
    value: masterData.PROPERTY_TYPE_ENUM.APARTMENT,
    title: "Apartment",
  },
  {
    value: masterData.PROPERTY_TYPE_ENUM.HOUSE,
    title: "House",
  },
  {
    value: masterData.PROPERTY_TYPE_ENUM.LAND,
    title: "Land",
  },
  {
    value: masterData.PROPERTY_TYPE_ENUM.OFFICE,
    title: "Office",
  },
  {
    value: masterData.PROPERTY_TYPE_ENUM.TOWNHOUSE,
    title: "Townhouse",
  },
  {
    value: masterData.PROPERTY_TYPE_ENUM.UNIT,
    title: "Unit",
  },
  {
    value: masterData.PROPERTY_TYPE_ENUM.VILLA,
    title: "Villa",
  },
];

export const UNIT_OPTION = [
  {
    value: masterData.UNIT_ENUM.ACRE,
    title: "Acre",
  },
  {
    value: masterData.UNIT_ENUM.HECTARE,
    title: "Hectare",
  },
  {
    value: masterData.UNIT_ENUM.SQUARE,
    title: "Square",
  },
  {
    value: masterData.UNIT_ENUM.SQUARE_METER,
    title: "Square meter",
  },
];

export const BEDROOM_OPTION = [
  // {
  //   title: "1",
  //   id: 1,
  //   value: 1,
  // },
  {
    title: "2",
    id: 2,
    value: 2,
  },
  {
    title: "3",
    id: 3,
    value: 3,
  },
  {
    title: "4",
    id: 4,
    value: 4,
  },
  {
    title: "5",
    id: 5,
    value: 5,
  },
];

export const PARKING_STALLS = [
  {
    title: "2",
    value: 2,
  },
  {
    title: "3",
    value: 3,
  },
];

export const REACTUI_PAGES = {
  NONE: "NONE",
  INTRODUCE_PAGE: "INTRODUCE_PAGE",
  AMENITY_PAGE: "AMENITY_PAGE",
  UNIT_EXPLORER_PAGE: "UNIT_EXPLORER_PAGE",
  GALLERY_PAGE: "GALLERY_PAGE",
  IMMERSE_PAGE: "IMMERSE_PAGE",
  TEAM_PAGE: "TEAM_PAGE",
  LOCATION_PAGE: "LOCATION_PAGE",
  ONBOARD_PAGE: "ONBOARD_PAGE",
  EXECUTIVE_SUMMARY_PAGE: "EXECUTIVE_SUMMARY_PAGE",
  SEARCH_PAGE: "SEARCH_PAGE",
  LANDING_PAGE: "LANDING_PAGE",
  EXPLORE_TRANSPORTS_PAGE: "EXPLORE_TRANSPORTS_PAGE",
  EXPLORE_PRECINCT_PAGE: "EXPLORE_PRECINCT_PAGE",
  EXPLORE_DISTRICT_PAGE: "EXPLORE_DISTRICT_PAGE",
  EXPLORE_CITY_PAGE: "EXPLORE_CITY_PAGE",
  PRECINCT_SUMMARY_PAGE: "PRECINCT_SUMMARY_PAGE",
  ENVIRONMENT_PAGE: "ENVIRONMENT_PAGE",
  END_GUIDE_TENANT_SESSION_PAGE: "END_GUIDE_TENANT_SESSION_PAGE",
  ABOUT_PAGE: "ABOUT_PAGE",
  LIFE_STYLE_PAGE: "LIFE_STYLE_PAGE",
  AMENITIES_PAGE: "AMENITIES_PAGE",
  AMENITIES_PAGE_V2: "AMENITIES_PAGE_V2",
  VIEWS_PAGE: "VIEWS_PAGE",
  SERVICES_PAGE: "SERVICES_PAGE",
  AMENITIES_OPTION_PAGE: "AMENITIES_OPTION_PAGE",
  GALLERY_LANDING_PAGE: "GALLERY_LANDING_PAGE",
  VIEWS_LEVEL: "VIEWS_LEVEL",
  SESSION_PAGE: "SESSION_PAGE",
  EXPLORE_PAGE: "EXPLORE_PAGE",
  NEIGHBORHOOD_PAGE: "NEIGHBORHOOD_PAGE",
  PPG_PAGE: "PPG_PAGE",
  AMENITIES_LANDING_PAGE: "AMENITIES_LANDING_PAGE",
  POST_SESSION_PAGE: "POST_SESSION_PAGE",
  GALLERY_PAGE_TYPE_B: "GALLERY_PAGE_TYPE_B",
  GALLERY_PAGE_TYPE_A: "GALLERY_PAGE_TYPE_A",
  STATIC_PAGE: "STATIC_PAGE",
};

export const LAYERS = {
  NONE: 0,
  HOTPOT: 1,
  UNITS: 2,
  FEATURE_HOUSE: 3,
  D_HOTSPOT: 4,
  EXPLORE_DISTRICT_HOTSPOT: 5,
  PINS_DO: 6,
  DO_BUILDING: 7,
  LANDING: 8,
  EXPLORE_CITY_ONLY: 9,

  BUILDING_23: 13,
  BUILDING_25: 15,
  BUILDING_27: 17,
  BUILDING_29: 19,
  BUILDING_31: 21,

  DISABLE: 31,
};

export const OBJECT_TYPES = {
  NONE: "none",
  DO: "do",
  FD: "fd",
  UNIT: "unit",
  AREA: "area",
  AMENITY: "amenity",
};
export const HOTSPOT_TYPES = {
  GALLERY: "gallery",
  PANO_TOUR: "360_pano_tour",
  URL: "url",
  VIDEO: "video",
  EXCUTIVE_SUMMARY: "excutive_summary",
  AMENITY: "amenity",
  AMENITY_OPTION: "amenity_option",
  UNIT_EXPLORE: "unit_explore",
  MODAL: "modal",
  AMENITY_ITEM: "amenity_item",
  EXPLORE_DISTRICT: "explore_district",
  EXPLORE_DISTRICT_DETAIL: "explore_district_detail",
  FUTURE_ITEM: "future_item",
  EXPLORE_DISTRICT_PAGE: "explore_district_page",
};

export default {
  MEDIA_TYPE_OPTION,
  PROPERTY_TYPE_OPTION,
  UNIT_OPTION,
  PARKING_STALLS,
  REACTUI_PAGES,
  LAYERS,
  OBJECT_TYPES,
  HOTSPOT_TYPES,
  AVAILABILITY_OPTIONS,
};

export const GROSS_RENT_OPTION = [
  {
    id: 1,
    text: "$50 - 100 PSM",
    min: 50,
    max: 100,
  },
  {
    id: 2,
    text: "$100 - 200 PSM",
    min: 100,
    max: 200,
  },
  {
    id: 3,
    text: "$200+ PSM",
    min: 200,
  },
];

export const CENTRE_OPTION = [
  {
    text: "Existing Centre",
    value: "Existing Centre",
  },
  {
    text: "Cinema Building",
    value: "Cinema Building",
  },
  {
    text: "Fresh Food",
    value: "Fresh Food",
  },
];

export const LEVEL_OPTION = [
  {
    text: "Ground",
    value: "GROUND",
  },
  {
    text: "Level 1",
    value: "LEVEL ONE",
  },
];

export const AREA_OPTION = [
  {
    id: 1,
    min: 10,
    max: 50,
    text: "10 - 50 sqm",
  },
  {
    id: 2,
    min: 50,
    max: 200,
    text: "50 - 200 sqm",
  },
  {
    id: 3,
    min: 200,
    max: 500,
    text: "200 - 500 sqm",
  },
  {
    id: 4,
    min: 500,
    text: "500+ sqm",
  },
];

export const PRECINCT_OPTION = [
  {
    text: "Entertainment and F&B",
    value: "Entertainment and F&B",
  },
  {
    text: "General Retail",
    value: "General Retail",
  },
  {
    text: "Health & Wellbeing",
    value: "Health & Wellbeing",
  },
  {
    text: "Lifestyle & Tourism",
    value: "Lifestyle & Tourism",
  },
  {
    text: "Commercial Office",
    value: "Commercial Office",
  },
  {
    text: "Fresh Food and Daily Needs",
    value: "Fresh Food and Daily Needs",
  },
];

export const TAB_GALLERY = {
  ORLA: "ORLA",
  ORLA_INFINITY: "ORLA Infinity",
  ORLA_MANSION: "ORLA Mansion",
  ORLA_SKY_PALACES: "ORLA Sky Palaces",
};

export const SUB_TAB_GALLERY = {
  RENDER: "RENDERS",
  FILMS: "FILMS",
  FLOOR_PLANS: "FLOORPLANS",
};

export const TAB = [
  {
    name: TAB_GALLERY.ORLA,
    isActive: true,
    width: 76,
  },
  {
    name: TAB_GALLERY.ORLA_INFINITY,
    isActive: false,
    width: 133,
  },
  {
    name: TAB_GALLERY.ORLA_MANSION,
    isActive: false,
    width: 143,
  },
  {
    name: TAB_GALLERY.ORLA_SKY_PALACES,
    isActive: false,
    width: 169,
  },
];

export const SUB_TAB = [
  {
    name: SUB_TAB_GALLERY.FILMS,
  },
  {
    name: SUB_TAB_GALLERY.FLOOR_PLANS,
  },
  {
    name: SUB_TAB_GALLERY.RENDER,
  },
];

export const GALLERY_TYPE = {
  VIDEO: "video",
  IMAGE: "image",
  VIRTUAL_TOUR: "virtual_tour",
  MEDIA_IMAGES: "media_images",
  FILMS: "films",
};

export const WEBSOCKET_CHANNEL = {
  SHARE_UI_ACTION: "share_screen_ui_action",
  SHARE_CAMERA_ACTION: "share_screen",
  SHARE_3D_BUILDING_ACTION: "share_3d_building_action",
  SHARE_PANORAMA_ACTION: "share_panorama_action",
};

export const ACTION_NAME = {
  START_SESSION: "START_SESSION",
  END_SESSION: "END_SESSION",
  SKIP_SESSION_VIDEO: "SKIP_SESSION_VIDEO",
  CLICK_LOCATION_MENU: "CLICK_LOCATION_MENU",
  CLICK_CUBE_MENU: "CLICK_CUBE_MENU",
  CLICK_EXPLORE_MENU: "CLICK_EXPLORE_MENU",
  CLICK_GALLERY_MENU: "CLICK_GALLERY_MENU",
  CLICK_TEAM_MENU: "CLICK_TEAM_MENU",
  CLICK_IMMERSER_MENU: "CLICK_IMMERSER_MENU",
  CLICK_SEARCH_MENU: "CLICK_SEARCH_MENU",
  CLICK_AMENITY_MENU: "CLICK_AMENITY_MENU",
  CLICK_TRANSPORT_MENU: "CLICK_TRANSPORT_MENU",
  CLICK_NEIGHBORHOOD_MENU: "CLICK_NEIGHBORHOOD_MENU",
  CLICK_PPG_MENU: "CLICK_PPG_MENU",
  CLICK_START_DISCOVER: "CLICK_START_DISCOVER",
  CLICK_UNIT_FILTER: "CLICK_UNIT_FILTER",
  CLEAR_ALL_FILTERS: "CLEAR_ALL_FILTERS",
  HIDE_FILTER: "HIDE_FILTER",
  CLICK_UNIT: "CLICK_UNIT",
  CLICK_HOTSPOT: "CLICK_HOTSPOT",
  CLICK_AMENITY_ITEM: "CLICK_AMENITY_ITEM",
  CLICK_TRANSPORT_ITEM: "CLICK_TRANSPORT_ITEM",
  CLICK_CLOSE_MODAL: "CLICK_CLOSE_MODAL",
  CLICK_MODAL_BUTTON: "CLICK_MODAL_BUTTON",
  CHANGE_MODAL_CONTENT: "CHANGE_MODAL_CONTENT",
  SHOW_UNIT_GALLERY: "SHOW_UNIT_GALLERY",
  CLOSE_UNIT_GALLERY: "CLOSE_UNIT_GALLERY",
  SHOW_UNIT_FLOORPLAN: "SHOW_UNIT_FLOORPLAN",
  CLOSE_UNIT_FLOORPLAN: "CLOSE_UNIT_FLOORPLAN",
  SHOW_UNIT_VIEWLINE: "SHOW_UNIT_VIEWLINE",
  CLOSE_UNIT_VIEWLINE: "CLOSE_UNIT_VIEWLINE",
  CLICK_UNIT_DETAIL_INFO: "CLICK_UNIT_DETAIL_INFO",
  CLICK_EYE_ICON: "CLICK_EYE_ICON",
  CLICK_NEXT_GALLERY: "CLICK_NEXT_GALLERY",
  CLICK_PREV_GALLERY: "CLICK_PREV_GALLERY",
  CHANGE_SLIDE_GALLERY: "CHANGE_SLIDE_GALLERY",
  CLICK_FLOORPLAN_SLIDE_BTN: "CLICK_FLOORPLAN_SLIDE_BTN",
  ZOOM_IN_FLOORPLAN: "ZOOM_IN_FLOORPLAN",
  ZOOM_OUT_FLOORPLAN: "ZOOM_OUT_FLOORPLAN",
  CLICK_SEARCH_UNIT: "CLICK_SEARCH_UNIT",
  CLICK_SEARCH_AMENITY: "CLICK_SEARCH_AMENITY",
  CLICK_SEARCH_POI: "CLICK_SEARCH_POI",
  CHANGE_POI_MODAL: "CHANGE_POI_MODAL",
  CLICK_POI_GROUP: "CLICK_POI_GROUP",
  CLICK_POI: "CLICK_POI",
  CLICK_POI_ITEM: "CLICK_POI_ITEM",
  CLOSE_POI_MODAL: "CLOSE_POI_MODAL",
  CHANGE_POI_GROUP: "CHANGE_POI_GROUP",
  CHANGE_ACTIVE_POI: "CHANGE_ACTIVE_POI",
  CHANGE_ACTIVE_TYPE: "CHANGE_ACTIVE_TYPE",
  WINDOW_SIZE: "WINDOW_SIZE",
  CHANGE_PANORAMA_CAMERA: "CHANGE_PANORAMA_CAMERA",
  CLOSE_PANO_VIEWER: "CLOSE_PANO_VIEWER",
  CLICK_END_SESSION: "CLICK_END_SESSION",
  SHOW_INTRO_SLIDE: "SHOW_INTRO_SLIDE",
  CHANGE_INTRO_SLIDE: "CHANGE_INTRO_SLIDE",
  EXPLORE_SHOW_FILTER: "EXPLORE_SHOW_FILTER",
  CLICK_ABOUT_MENU: "CLICK_ABOUT_MENU",
  CLICK_LIFE_STYLE_MENU: "CLICK_LIFE_STYLE_MENU",
  CLICK_LANGUAGE_BUTTON: "CLICK_LANGUAGE_BUTTON",
  SELECT_LANGUAGE: "SELECT_LANGUAGE",
  CLOSE_LANGUAGE: "CLOSE_LANGUAGE",
  CLICK_REPLAY_VIDEO: "CLICK_REPLAY_VIDEO",
  CLICK_SKIP_REPLAY_VIDEO: "CLICK_SKIP_REPLAY_VIDEO",
  PAGE_SCROLL: "PAGE_SCROLL",
  PAGE_SCROLL_UNIT_DETAIL: "PAGE_SCROLL_UNIT_DETAIL",
  ENABLE_TRANSPARENT_MODE: "ENABLE_TRANSPARENT_MODE",
  DISABLE_TRANSPARENT_MODE: "DISABLE_TRANSPARENT_MODE",
  OPEN_FEATURE_DETAIL: "OPEN_FEATURE_DETAIL",
  CLOSE_FEATURE_DETAIL: "CLOSE_FEATURE_DETAIL",
  CHANGE_ACTIVE_GALLERY_TAB: "CHANGE_ACTIVE_GALLERY_TAB",
  CHANGE_ACTIVE_SUB_GALLERY_TAB: "CHANGE_ACTIVE_SUB_GALLERY_TAB",
  SHOW_GALLERY: "SHOW_GALLERY",
  CLOSE_GALLERY: "CLOSE_GALLERY",
  SHOW_NEXT_GALLERY: "SHOW_NEXT_GALLERY",
  SHOW_PREV_GALLERY: "SHOW_PREV_GALLERY",
  PLAY_VIDEO: "PLAY_VIDEO",
  STOP_VIDEO: "STOP_VIDEO",
  SHOW_FLOOR_MODAL: "SHOW_FLOOR_MODAL",
  HIDE_FLOOR_MODAL: "HIDE_FLOOR_MODAL",
  SHOW_GALLERY_DATA: "SHOW_GALLERY_DATA",
  SELECT_FLOOR: "SELECT_FLOOR",
  SWITCH_FLOOR: "SWITCH_FLOOR",
  SHOW_VIEW_PLAN: "SHOW_VIEW_PLAN",
  CLOSE_VIEW_PLAN: "CLOSE_VIEW_PLAN",
  CLOSE_RENDER_GALLERY: "CLOSE_RENDER_GALLERY",
  TOAST_MESSAGE: "TOAST_MESSAGE",
  GOOGLE_MAP_ZOOM_CHANGED: "GOOGLE_MAP_ZOOM_CHANGED",
  GOOGLE_MAP_CENTER_CHANGED: "GOOGLE_MAP_CENTER_CHANGED",
  SHOW_AMENITY_MODAL_IMAGE: "SHOW_AMENITY_MODAL_IMAGE",
  CLOSE_AMENITY_MODAL_IMAGE: "CLOSE_AMENITY_MODAL_IMAGE",
  SYNC_CUSTOMER_END_SESSION: "SYNC_CUSTOMER_END_SESSION",
  RESET_CUSTOMER: "RESET_CUSTOMER",
  SHOW_AMENITY_VIRTUAL_TOUR: "SHOW_AMENITY_VIRTUAL_TOUR",
  CLOSE_AMENITY_VIRTUAL_TOUR: "CLOSE_AMENITY_VIRTUAL_TOUR",
  NEXT_IMAGE: "NEXT_IMAGE",
  PREV_IMAGE: "PREV_IMAGE",
  SET_ACTIVE_FLOORPLAN: "SET_ACTIVE_FLOORPLAN",
  CLOSE_ACTIVE_FLOORPLAN: "CLOSE_ACTIVE_FLOORPLAN",
  ZOOM_OUT_FLOORPLAN_SESSION: "ZOOM_OUT_FLOORPLAN_SESSION",
  CREATE_PROFILE_SUCCESS: "CREATE_PROFILE_SUCCESS",
  SHOW_RENDER_GALLERY: "SHOW_RENDER_GALLERY",
  SHOW_UNIT_360: "SHOW_UNIT_360",
  CLOSE_UNIT_360: "CLOSE_UNIT_360",
  CLICK_GALLERY_B: "CLICK_GALLERY_B",
  CLICK_GALLERY_A: "CLICK_GALLERY_A",
  SHOW_360_GALLERY: "SHOW_360_GALLERY",
  CLOSE_360_GALLERY: "CLOSE_360_GALLERY",
  JUMP_TO_RENDER_360: "JUMP_TO_RENDER_360",
  PAGE_SCROLL_360: "PAGE_SCROLL_360",
  GALLERY_B_SCROLL: "GALLERY_B_SCROLL",
  SET_GALLERY_INDEX: "SET_GALLERY_INDEX",
};
