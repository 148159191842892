import React, { useCallback, useEffect, useState } from "react";
import FormInput from "./form-input";
import authApi from "../../apis/api/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { validatePassword } from "../../helper/validation";
import { cn } from "../../helper/utils";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import _ from "lodash";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const FormResetPassword = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [message, setMessage] = useState({});
  const [dirty, setDirty] = useState({});

  const checkExpiredToken = useCallback(async () => {
    const res = await authApi.checkExpiredToken({
      token: query.get("token"),
    });
    if (!res?.data || !_.isEmpty(res?.data)) {
      navigate("/forgot-password");
      toast.error("Expired token");
    }
  }, []);

  useEffect(() => {
    (async () => await checkExpiredToken())();
  }, []);

  useEffect(() => {
    if (!dirty.password) return;
    validatePasswordFunc();
  }, [password, dirty.password]);

  useEffect(() => {
    if (!dirty.passwordConfirmation) return;
    validatePasswordConfirmFunc();
  }, [passwordConfirmation, dirty.passwordConfirmation]);

  const validatePasswordFunc = () => {
    if (validatePassword(password) && dirty.password) {
      delete message.password;
      setMessage({ ...message });
      return true;
    } else {
      message.password = "Invalid password";
      setMessage({ ...message });
      return false;
    }
  };

  const validatePasswordConfirmFunc = () => {
    if (password === passwordConfirmation && dirty.passwordConfirmation) {
      delete message.passwordConfirmation;
      setMessage({ ...message });
      return true;
    } else {
      message.passwordConfirmation = "Password confirmation not match";
      setMessage({ ...message });
      return false;
    }
  };

  const handleResetPassword = async () => {
    if (!validatePasswordFunc() || !validatePasswordConfirmFunc()) return;
    const res = await authApi.resetPassword({
      password: password,
      password_confirmation: passwordConfirmation,
      token: query.get("token"),
    });

    const newRes = JSON.parse(JSON.stringify(res));

    const data = newRes?.data || newRes?.response?.data || {};
    const message = newRes?.message || newRes?.response?.message || "";

    if (message === "Token is expired" || data?.token === "Token is expired") {
      navigate("/forgot-password", { replace: true });
      toast.error("Token is expired");
    } else if (
      message === "Token is invalid" ||
      data?.token === "Token is invalid"
    ) {
      toast.error(message || data?.message || "Invalid token");
    } else {
      toast.success("Password reset successfully");
      navigate("/holding", { replace: true });
    }
  };

  return (
    <div className="flex flex-col justify-center w-[534px]">
      <FormInput
        type="password"
        onChange={(e) => {
          setDirty({ ...dirty, password: true });
          setPassword(e.target.value);
        }}
        id="email"
        placeholder="Password"
      />
      {message.password && (
        <p className={cn("text-red-600 mt-2")}>{message.password}</p>
      )}
      <FormInput
        type="password"
        onChange={(e) => {
          setDirty({ ...dirty, passwordConfirmation: true });
          setPasswordConfirmation(e.target.value);
        }}
        className="mt-6"
        id="email"
        placeholder="Password Confirmation"
      />
      {message.passwordConfirmation && (
        <p className={cn("text-red-600 mt-2")}>
          {message.passwordConfirmation}
        </p>
      )}
      <div className="bg-button-bottom">
        <button
          type="submit"
          onClick={handleResetPassword}
          className="flex justify-center items-center border-none bg-buttonPrimary w-full h-[60px] mt-[25px] mb-6"
        >
          <span className="text-p text-primary text-center uppercase tracking-[2.25px]">
            ENTER
          </span>
        </button>
      </div>
    </div>
  );
};

export default FormResetPassword;
