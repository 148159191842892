import React from "react";
import { SUB_COMPONENTS_TYPES_ENUM } from "../../../../constants/modules";
import { Checkbox } from "../../../svgs/icons";

const FilterCheckbox = (props) => {
  const {
    item,
    filterValues,
    onChange,
    visible = false,
    value = "filter value",
    label = "Option 1",
    order = 0,
    className = "",
  } = props;

  if (!visible) return null;

  return (
    <li className={`${className}  border-none text-sm opacity-100 flex`}>
      <div className="pretty whitespace-pre-wrap p-svg p-plain">
        <input
          type="checkbox"
          checked={
            Array.isArray(filterValues)
              ? filterValues.map((f) => f.value).includes(value)
              : String(filterValues) === String(value)
          }
          onChange={(e) =>
            onChange(
              e.target.checked,
              { id: item.id, title: label, value: value },
              filterValues
            )
          }
          className="before:!w-[calc(1em_+_1px)] before:!h-[calc(1em_+_1px)] after:!w-[calc(1em_+_1px)] after:!h-[calc(1em_+_1px)]"
        />
        <div className="state">
          <Checkbox className="svg !top-0" fill="var(--brand-border-color)" />
          <label className="text-primary text-p !font-normal tracking-[-0.36px] !ml-[16px] !pl-[16px] !indent-0 before:!top-0 after:!top-0 after:!border-brand">
            {label}
          </label>
        </div>
      </div>
    </li>
  );
};
FilterCheckbox.type = SUB_COMPONENTS_TYPES_ENUM.CHECKBOX;

export default FilterCheckbox;
