import querystring from "querystring-es3";
import qs from "qs";
import Config from "../config";

export default class ApiClient {
  constructor(fetcher) {
    this.api_host = Config.API_URL;
    this.fetcher = fetcher;
  }

  get(endpoint, params) {
    const query = params
      ? `?${qs.stringify(params, { skipNulls: true, skipEmptyStrings: true })}`
      : "";
    return this.fetcher(this.api_host + endpoint + query);
  }

  getBody(endpoint, data) {
    return this.fetcher(this.api_host + endpoint, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  getToken(endpoint, data, token) {
    return this.fetcher(this.api_host + endpoint, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  post(endpoint, data, rejectInvalid) {
    return this.fetcher(
      this.api_host + endpoint,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          apptype: "WEB_APP",
        },
        body: JSON.stringify(data),
      },
      rejectInvalid
    );
  }

  put(endpoint, data) {
    return this.fetcher(this.api_host + endpoint, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  patch(endpoint, data) {
    return this.fetcher(this.api_host + endpoint, {
      method: "PATCH",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  delete(endpoint) {
    return this.fetcher(this.api_host + endpoint, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  uploadFormData(endPoint, data) {
    const url = this.api_host + endPoint;

    return this.fetcher(url, {
      method: "post",
      headers: {
        Accept: "application/json",
      },
      body: data,
    });
  }

  editFormData(endPoint, data) {
    const url = this.api_host + endPoint;

    return this.fetcher(url, {
      method: "put",
      headers: {
        Accept: "application/json",
      },
      body: data,
    });
  }

  uploadImage(endpoint, data) {
    const formData = new FormData();
    formData.append("imageFile", data);
    return this.uploadFile(endpoint, undefined, formData);
  }

  /* istanbul ignore next: it's very hard to test */
  uploadFile(endpoint, params, data, onProgress, headers) {
    const query = params ? `?${querystring.stringify(params)}` : "";
    const url = this.api_host + endpoint + query;
    return this.fetcher(
      url,
      {
        method: "POST",
        mode: "cors",
        headers,
        body: data,
      },
      onProgress
    );
  }

  /* istanbul ignore next: it's very hard to test */
  downloadFile(endpoint, params, data, onProgress) {
    const query = params ? `?${querystring.stringify(params)}` : "";
    const url = this.api_host + endpoint + query;
    return this.fetcher(
      url,
      {
        method: "GET",
        headers: {
          "Cache-Control": "no cache",
        },
        body: data,
      },
      onProgress
    );
  }
}
