/** @format */

import React from "react";
import { COMPONENTS_ENUM } from "../../../../constants/modules";

const LocationGroup = React.forwardRef((props, ref) => {
  const {
    category,
    pois,
    onClickCategory,
    onClickItem,
    activeCategory,
    activePoi,
    visible = false,
  } = props;

  if (!visible) return null;

  return (
    <div className="flex w-[215px] flex-col items-start gap-[25px]">
      <div className="text-white flex flex-col justify-end items-start self-stretch cursor-pointer">
        <span
          className={`text-[--location-sidebar-text-color] text-panelSubHeader uppercase
            ${
              activeCategory && activeCategory !== category ? "opacity-40" : ""
            }`}
          onClick={() => onClickCategory(category)}
        >
          {category}
        </span>
      </div>
      <div className="flex flex-col items-start gap-3 self-stretch">
        {pois.map((poi) =>
          poi.category === category ? (
            <li
              className={`list-none text-white items-start cursor-pointer ${
                (activePoi && activePoi !== poi.id) || activeCategory
                  ? "opacity-40"
                  : ""
              }`}
              key={poi.id}
              onClick={() => onClickItem(poi)}
            >
              <span className="text-[--location-sidebar-text-color] text-p uppercase !font-normal">
                -
              </span>
              <span className="text-[--location-sidebar-text-color] text-p !font-normal ml-3">
                {poi.title}
              </span>
            </li>
          ) : null
        )}
      </div>
    </div>
  );
});
LocationGroup.displayName = COMPONENTS_ENUM.LOCATION_GROUP;

export default LocationGroup;
