import React from "react";
import { InputField, TextareaField } from "../../components/commons";

const NoImage = ({ index, control }) => {
  return (
    <div className="my-5">
      <div className="border-[1px] py-5 relative rounded-lg border-solid">
        <div className="flex flex-wrap px-10 items-center justify-center">
          <InputField
            controller={{
              control,
              name: `sections.${index}.title`,
            }}
            inputProps={{
              placeholder: "Title",
              maxLength: 100,
            }}
            wrapperClassName="w-full mb-4"
          />
          <TextareaField
            wrapperClassName="w-full"
            controller={{
              control,
              name: `sections.${index}.description`,
            }}
            textareaProps={{
              placeholder: "Description",
              maxLength: 500,
              rows: 4,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NoImage;
