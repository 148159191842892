import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authApi from "../../apis/api/auth";
import ROUTE_PATH from "../../routers/path";
import FormInput from "./form-input";
import jwtAuth from "../../apis/utils/jwtAuth";

const FormLogin = ({ showSecondaryBtn = true, postLoginRedirectUrl = "/" }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (email || password) {
      setErrorMessage("");
    }
  }, [email, password]);

  const handleForgotPassword = () => {
    navigate(ROUTE_PATH.FORGOT_PASSWORD);
  };

  const handleLogin = async () => {
    const data = {
      email: email,
      password: password,
    };

    const res = await authApi.signIn(data);

    if (res?.data) {
      jwtAuth.setAccessToken(res.data.token);
      navigate(postLoginRedirectUrl || "/");
    } else {
      setErrorMessage("Email not found or password does not match");
    }
  };

  return (
    <div className="flex flex-col justify-center w-[534px]">
      <FormInput
        type="email"
        onChange={(e) => setEmail(e.target.value)}
        className="mb-6"
        placeholder={"Email"}
      />
      <FormInput
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        id="password"
        placeholder="Password"
      />
      {errorMessage && (
        <p id="passwordHelp" className="text-red-600 mt-2">
          {errorMessage}
        </p>
      )}
      <div className="bg-button-bottom">
        <button
          type="submit"
          onClick={handleLogin}
          className="flex justify-center items-center border-none bg-buttonPrimary w-full h-[60px] mt-[25px] mb-6"
        >
          <span className="text-p text-primary text-center uppercase tracking-[2.25px]">
            ENTER
          </span>
        </button>
      </div>
      {showSecondaryBtn && (
        <span
          onClick={handleForgotPassword}
          className="link-btn text-primary text-p text-center leading-7 uppercase h-5 cursor-pointer tracking-widest hover:underline"
        >
          Reset password
        </span>
      )}
    </div>
  );
};

export default FormLogin;
