import { formatMoney } from "../../../../helper/unit";
import React, { forwardRef, useEffect, useState, useCallback } from "react";

const FilterGroupRange = (
  {
    onChange,
    label,
    filterValue,
    icon = "$$$$",
    max,
    min = 0,
    customClass = "",
    order = 0,
  },
  ref
) => {
  const [value, setValue] = useState(filterValue);

  useEffect(() => {
    setValue(filterValue);
  }, [filterValue]);

  const onChangeValue = (e) => {
    let data = {
      ...filterValue,
    };
    const maxValue = filterValue.max || max;
    const minvalue = filterValue.min || +min;
    let name = e.target.name;
    let value = e.target.value;
    try {
      value = parseInt(value);
    } catch (error) {
      value = 0;
    }
    if (name === "min") {
      if (!value || value < 0) {
        value = 0;
      }
      if (value >= maxValue) {
        value = maxValue;
      }
      data = {
        max: maxValue,
        min: value,
      };
    }
    if (name === "max") {
      if (!value) {
        value = 0;
      }
      if (value > max) {
        value = max;
      }
      if (value <= minvalue) {
        value = minvalue;
      }
      data = {
        max: value,
        min: minvalue,
      };
    }
    onChange && onChange(data);
  };
  return (
    <div
      className={`filter-group flex flex-col items-start self-stretch gap-[25px]`}
    >
      <h2 className="text-primary text-panelSubHeader">{label.title}</h2>
      <div
        className={`flex w-full justify-between flex-col gap-2 ${customClass}`}
      >
        <div className="flex flex-col items-start gap-[5px] flex-[1_0_0] bg-transparent px-[10px] py-[5px] border-[1px] border-brand text-center">
          <small className="font-sans text-[10px] font-normal leading-none tracking-[1px] text-primary opacity-60">
            {label.min}
          </small>
          <input
            name="min"
            value={
              value?.min != null
                ? icon === "$$$$"
                  ? formatMoney(value.min)
                  : value.min
                : min || 0
            }
            onChange={onChangeValue}
            className="text-primary !font-normal text-p tracking-[1.4px] leading-[1px] uppercase bg-transparent p-[0] !border-none rounded-0 placeholder:!text-white placeholder:opacity-100 w-full"
            type="number"
            placeholder={icon}
          />
        </div>
        <div className="flex flex-col items-start gap-[5px] flex-[1_0_0] bg-transparent px-[10px] py-[5px] border-[1px] border-brand text-center">
          <small className="font-sans text-[10px] font-normal leading-none tracking-[1px] text-primary opacity-60">
            {label.max}
          </small>
          <input
            name="max"
            value={
              value?.max != null
                ? icon === "$$$$"
                  ? formatMoney(value.max)
                  : value.max
                : icon === "$$$$"
                ? formatMoney(max)
                : max
            }
            onChange={onChangeValue}
            className="text-primary text-p !font-normal tracking-[1.4px] leading-[1px] uppercase bg-transparent p-[0] !border-none rounded-0 placeholder:!text-white placeholder:opacity-100 w-full"
            type="number"
            placeholder={icon}
          />
        </div>
      </div>
    </div>
  );
};
export default forwardRef(FilterGroupRange);
